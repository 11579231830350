import React, { useRef, useState, forwardRef } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls, Html } from "@react-three/drei";
import * as THREE from "three";
import moontexture from "../assets/moon.jpg";
import moonNormal from "../assets/normal.jpg";
import startexture from "../assets/star.png";
import gsap from "gsap";
import { useQuery } from "@apollo/client";
import { GET_CONSTELLATION } from "../Services/gql/query";
import MoonLoader from "../Comnponents/MoonLoader";
import {
  Flex,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon, InfoIcon, StarIcon } from "@chakra-ui/icons";

// Star Component
export const Star = forwardRef(({ id, position, info, color }, ref) => {
  const [selected, setSelected] = useState(false);
  const starTexture = new THREE.TextureLoader().load(startexture);
  const starRef = useRef();

  React.useImperativeHandle(ref, () => ({
    starRef: starRef.current,
    // Add more properties or methods if needed
  }));

  useFrame(({ camera }) => {
    if (starRef?.current) {
      //console.log(ref);

      starRef.current.quaternion.copy(camera.quaternion); // Match the camera rotation
    }
  });

  return (
    <mesh
      ref={starRef}
      position={position}
      onClick={() => setSelected((prev) => !prev)}
    >
      <planeGeometry args={[1.5, 1.5]} />
      <meshBasicMaterial
        map={starTexture}
        transparent={true}
        side={THREE.DoubleSide}
      />
      {selected && (
        <Html center>
          <Box
            style={{
              background: "#202035",
              color: "white",
              padding: "5px 10px",
              borderRadius: "5px",
              border: `1px solid ${color}`,
              marginTop: "30px",
              pointerEvents: "auto",
              cursor: "pointer",
            }}
            onClick={() => setSelected(false)}
          >
            {info}
          </Box>
        </Html>
      )}
    </mesh>
  );
});

// Moon Component
const Moon = () => {
  const moonRef = useRef();

  useFrame(() => {
    if (moonRef.current) {
      moonRef.current.rotation.y += 0.001;
    }
  });

  const moonTexture = new THREE.TextureLoader().load(moontexture);
  const normalTexture = new THREE.TextureLoader().load(moonNormal);

  return (
    <mesh ref={moonRef}>
      <sphereGeometry args={[10, 32, 32]} />
      <meshStandardMaterial map={moonTexture} normalMap={normalTexture} />
    </mesh>
  );
};

// Scene Component
const Scene = ({ data, focusStarRef }) => {
  const { camera } = useThree();
  const starRefs = useRef({}); // Store references to stars

  // Expose focusStar via ref to parent
  React.useImperativeHandle(focusStarRef, () => ({
    focusStar: (id) => {
      const star = starRefs.current[id];
      star.starRef.click();

      if (star) {
        const { x, y, z } = star.starRef.position;
        star.gsap // Smoothly move the camera
          .to(camera.position, {
            x: x,
            y: y,
            z: z,

            duration: 2,
            onUpdate: () => {
              camera.lookAt(new THREE.Vector3(x, y, z));
            },

            onComplete: () => {
              console.log(`Camera focused on star ${id}`);
            },
          });
      } else {
        console.error(`Star with ID ${id} not found.`);
      }
    },
  }));

  return (
    <>
      <ambientLight intensity={0.5} />
      <pointLight position={[25, 50, 25]} intensity={1} />
      <OrbitControls enableDamping dampingFactor={0.05} enableZoom={true} />
      <Moon />
      {data.constellations.map((props) => (
        <Star
          key={props.id}
          id={props.id}
          position={[
            (Math.random() - 0.5) * 200,
            (Math.random() - 0.5) * 200,
            (Math.random() - 0.5) * 200,
          ]}
          info={`Star of ${props.NAME} ${props.SURNAME}, a ${props.CATEGORY} supporter!`}
          color={props.COLOR}
          ref={(el) => {
            if (el) {
              starRefs.current[props.id] = el;
            }
          }}
        />
      ))}
    </>
  );
};

// Main Constellation Component
const Constellation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loading, error, data } = useQuery(GET_CONSTELLATION, {
    fetchPolicy: "no-cache",
    context: {
      headers: {
        authorization: `${process.env.REACT_APP_GQLTOKEN}`,
      },
    },
  });

  const focusStarRef = useRef(); // Ref to access `focusStar` in `Scene`

  if (loading) return <MoonLoader color="white" />;
  if (error) return <p>Error: {error.message}</p>;

  const handleFocusStar = (id) => {
    if (focusStarRef.current) {
      focusStarRef.current.focusStar(id);
    }
  };

  return (
    <div>
      <Flex
        w="100%"
        minH="5vh"
        alignItems="end"
        sx={{ background: "black" }}
        pl={"10px"}
        pt={"5px"}
      >
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
          />
          <MenuList>
            <MenuItem icon={<InfoIcon />} onClick={onOpen}>
              About
            </MenuItem>
            <MenuItem
              icon={<StarIcon />}
              onClick={() => handleFocusStar("673d9f7f66b6638ce275636a")}
            >
              Find Your Star
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Constellation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>Some text here</p>
            <p>Click on each Star to reveal the owner.</p>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Canvas
        camera={{ position: [0, 0, 250], fov: 20 }}
        style={{ background: "black", height: "95vh" }}
      >
        <Scene data={data} focusStarRef={focusStarRef} />
      </Canvas>
    </div>
  );
};

export default Constellation;
